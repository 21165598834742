/**
 * About page
 */
/* Import section */
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"
import { IconContext } from "react-icons"
import { BsCompass } from "react-icons/bs"
import Bannerwithcta from "../components/bannerwithcta"
import Blockteam from "../components/blockteam"
import Titledecorative from "../components/titledecorative"
import Herotype1 from "../components/herotype1"
import Layout from "../components/layout"
import Sectionchecklist from "../components/sectionchecklist"
import Seo from "../components/seo"
import "../styles/pages/about-us.scss"


/* Page function declaration */
const Aboutpage = ({data}) => {

  //Const data
  const dataPage = data.wpPage
  const dataAcf = dataPage.acfPageAbout
  //Array for SliderHero
  let arrTextHero = []
  dataAcf.repeaterHeroAbout.map(texthero => (
    arrTextHero.push({textHero: texthero.introHeroAbout})
  ))

  //Arry for Help checklist
  let arrItemschecklist = []
  dataAcf.repeaterHelpAbout.map( (item)  => (
    arrItemschecklist.push({textChecklist: item.itemHelpAbout})
  ))

  //Const data Options
  const dataOptionsbanner = data.options.acfPageOptions
  var arrOptionsbanner = []
  arrOptionsbanner = dataOptionsbanner.repeaterBannerCta.filter(function(element){
    return element.topicBannerCta === "contact3"
  })

  return (
    <Layout>
      <Seo
        title={dataPage.seo.title}
        description={dataPage.seo.metaDesc}
        slickslider= "slick"
      />

      {/* Hero section */}
      <section className="section-hero-about top-section">
        <Herotype1 keyHero={dataPage.title} h1title={dataAcf.titleHeroAbout} arrIntro={arrTextHero} colorBar="blue" />
      </section>

      {/*About details section */}
      <section className="section-details-about">
        <div className="wrap-intro-details wrap-maincontent">
          <Titledecorative title={dataAcf.titleDetailsAbout} />
          <div className="flex-box">
            <div className="intro first-body" dangerouslySetInnerHTML={{ __html:dataAcf.textDetailsAbout}}/>
          </div>
          <div className="box-mission-about">
            <span className="icon-mission-about">
              <IconContext.Provider value={{ className: "icon-mission" }}>
                <BsCompass />
              </IconContext.Provider>
            </span>
            <div className="content-mission-about">
              <h3>{dataAcf.titleMissionAbout}</h3>
              <div dangerouslySetInnerHTML={{ __html:dataAcf.textMissionAbout}}/>
            </div>
          </div>
        </div>
      </section>

      {/*About help section */}
      <Sectionchecklist
        classSectionChecklist="section-help-about"
        titleSectionChecklist={dataAcf.titleHelpAbout}
        textSectionChecklist={dataAcf.textHelpAbout}
        arrItemschecklist= {arrItemschecklist}
        ctaLabelChecklist=""
        ctaLinkChecklist=""
        colorChecklist="blue"
        colorThemeSection="blue"
        colorCtaList="white-format"
      />

      {/*About what we do section */}
      <section className="section-what-we-do-about">
        <div className="wrap-intro-what-we-do-about wrap-maincontent">
          <Titledecorative title={dataAcf.titleWhatWeDoAbout} />
          <div className="flex-box">
            <div className="image-ambiance">
              <GatsbyImage image={dataAcf.imageWhatWeDoAbout.localFile.childImageSharp.gatsbyImageData} alt="about us ambiance" />
            </div>
            <div className="intro first-body" dangerouslySetInnerHTML={{ __html:dataAcf.textWhatWeDoAbout}}/>
          </div>
        </div>
      </section>


      {/*About leadership section */} 
      {/* commented by mc  bug 809*/}
      {/* <section className="section-leadership-about">
        <div className="wrap-maincontent wrap-leadership">
          <div className="box-leadership">
            <Titledecorative title={dataAcf.titleLeadershipAbout} />
            <div className="flex-box">
              <div className="intro first-body" dangerouslySetInnerHTML={{ __html:dataAcf.textLeadershipAbout}}/>
            </div>
            <div className="box-team">
              {dataAcf.repeaterLeadershipAbout.map((team, index) =>
                <Blockteam
                  keyTeam={index}
                  nameTeam={team.nameTeamLeadership}
                  titleTeam={team.titleTeamLeadership}
                  pictureTeam={team.pictureTeamLeadership.localFile.childImageSharp.gatsbyImageData}
                />
              )}
            </div>
          </div>
        </div>
      </section> */}

      {/* Banner CTA About */}
      <Bannerwithcta
        classBanner="section-banner-about"
        titleBanner={arrOptionsbanner[0].titleBannerCta}
        textBanner={arrOptionsbanner[0].textBannerCta}
        ctaLabelBanner={arrOptionsbanner[0].ctaLabelBanner}
        ctaLinkBanner={arrOptionsbanner[0].ctaLinkBanner}
      />
    </Layout>
  )
}

/* Query page */
export const query = graphql`
  query {
    wpPage(slug: {eq: "about-us"}) {
      acfPageAbout {
        imageWhatWeDoAbout {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED, width: 900, aspectRatio: 1.5)
            }
          }
        }
        repeaterHelpAbout {
          itemHelpAbout
        }
        repeaterHeroAbout {
          introHeroAbout
        }
        repeaterLeadershipAbout {
          nameTeamLeadership
          pictureTeamLeadership {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED, width: 200)
              }
            }
          }
          titleTeamLeadership
        }
        textDetailsAbout
        textHelpAbout
        textWhatWeDoAbout
        titleDetailsAbout
        textMissionAbout
        titleHelpAbout
        titleHeroAbout
        titleLeadershipAbout
        titleMissionAbout
        titleWhatWeDoAbout
      }
      title
      seo {
        title
        metaDesc
      }
    }
    options: wpPage(slug: {eq: "options-website"}) {
      acfPageOptions {
        repeaterBannerCta {
          ctaLabelBanner
          ctaLinkBanner
          textBannerCta
          titleBannerCta
          topicBannerCta
        }
      }
    }
  }
`

// backup added by mc   bug 809  (orginal)

// export const query = graphql`
//   query {
//     wpPage(slug: {eq: "about-us"}) {
//       acfPageAbout {
//         imageWhatWeDoAbout {
//           localFile {
//             childImageSharp {
//               gatsbyImageData(layout: CONSTRAINED, width: 900, aspectRatio: 1.5)
//             }
//           }
//         }
//         repeaterHelpAbout {
//           itemHelpAbout
//         }
//         repeaterHeroAbout {
//           introHeroAbout
//         }
//         repeaterLeadershipAbout {
//           nameTeamLeadership
//           pictureTeamLeadership {
//             localFile {
//               childImageSharp {
//                 gatsbyImageData(layout: CONSTRAINED, width: 200)
//               }
//             }
//           }
//           titleTeamLeadership
//         }
//         textDetailsAbout
//         textHelpAbout
//         textLeadershipAbout
//         textWhatWeDoAbout
//         titleDetailsAbout
//         textMissionAbout
//         titleHelpAbout
//         titleHeroAbout
//         titleLeadershipAbout
//         titleMissionAbout
//         titleWhatWeDoAbout
//       }
//       title
//       seo {
//         title
//         metaDesc
//       }
//     }
//     options: wpPage(slug: {eq: "options-website"}) {
//       acfPageOptions {
//         repeaterBannerCta {
//           ctaLabelBanner
//           ctaLinkBanner
//           textBannerCta
//           titleBannerCta
//           topicBannerCta
//         }
//       }
//     }
//   }
// `
/* Export page informations */
export default Aboutpage
